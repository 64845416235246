.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 1.5rem;
}

.menu-icon {
  font-size: 2rem;
  color: #1e212d;
}

.current-user {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &-email {
    font-size: 1rem;
    color: #1e212d;
    font-weight: 500;
    letter-spacing: 2px;

    @include sm {
      display: none;
    }
  }

  &-auth-btn {
    margin: 0 1rem;
    padding: 0.7rem 1.5rem;
    font-size: 1rem;
    background-color: #fefaee;
    color: #1e212d;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
}

.ais-SearchBox-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  min-width: 1000px;
  margin: 0 0.5rem;

  .ais-SearchBox-input {
    padding: 0 6rem 0 1rem;
    width: 100%;
    height: 3.5rem;
    margin: 0 0 1rem 0;

    font-size: inherit;
    font-family: inherit;
    background: #fefaee;
    border: 1px solid #fef7dc;
    color: #1e212d;
    box-sizing: border-box;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
    border-radius: 6px;

    &:focus {
      outline: 0;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  .ais-SearchBox-submit {
    position: absolute;
    right: 0;
    top: 0;
    min-height: 3.5rem;
    border-radius: 0 6px 6px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: #ffebc9;
    cursor: pointer;

    .search-logo {
      height: 2.7rem;
      width: 3.5rem;
      stroke: #1e212d;
      stroke-width: 4.5px;
    }
  }

  .ais-SearchBox-reset {
    position: absolute;
    right: 70px;
    border: none;
    background-color: transparent;
    margin: 0 0 1rem 0;
    cursor: pointer;

    svg {
      height: 20px;
      width: 20px;

      stroke: #fff;
      stroke-width: 1px;

      path {
        fill: #aaa;
      }
    }

    &:hover {
      svg {
        path {
          fill: #222;
        }
      }
    }

    @include sm {
      right: 45 px;
    }
  }

  @include sm {
    min-width: auto;
  }
}

.ais-Pagination {
  &-list {
    display: flex;
    list-style: none;
    padding-left: 1rem;
  }

  &-item {
    background-color: #fefaee;
    padding: 0.4rem 0.8rem;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    border-radius: 5px;

    &--selected {
      background-color: #ffebc9;
    }

    &--disabled {
      opacity: 0.6;
    }
  }

  &-link {
    text-decoration: none;
    display: inline-block;
    color: #1e212d;
  }
}

.ais-Hits {
  margin: 0 0.5rem;
  max-width: 1000px;
  .ais-Hits-list {
    margin: 0;
    padding: 0;

    .ais-Hits-item {
      background: #fefaee;
      border: 1px solid #fef7dc;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      list-style: none;
      padding: 1rem;
      margin: 1rem 0;

      .ais-Highlight {
        white-space: pre-line;
      }

      .ais-Highlight-highlighted {
        position: relative;
        font-weight: bolder;
        padding: 0.3rem 0;
        margin: 0;

        background: linear-gradient(
            104deg,
            rgba(130, 255, 173, 0) 0.9%,
            rgba(130, 255, 173, 1.25) 2.4%,
            rgba(130, 255, 173, 0.5) 5.8%,
            rgba(130, 255, 173, 0.1) 93%,
            rgba(130, 255, 173, 0.7) 96%,
            rgba(130, 255, 1732, 0) 98%
          ),
          linear-gradient(
            183deg,
            rgba(130, 255, 173, 0) 0%,
            rgba(130, 255, 173, 0.3) 7.9%,
            rgba(130, 255, 173, 0) 15%
          );
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        border-radius: 7.5px;
      }

      .wpProQuiz_respone_span {
        display: none;
      }
    }
  }
}

.ais-Stats {
  display: inline-block;
}

.title-box {
  display: flex;
  justify-content: space-evenly;

  @include sm {
    flex-direction: column;
    align-items: center;
  }
}

.text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 2rem;

  @include sm {
    padding: 1rem;
  }

  .primary-title {
    font-size: 4rem;
    font-weight: bold;
    letter-spacing: 2px;

    @include sm {
      font-size: 2.5rem;
    }
  }

  .secondary-title {
    font-size: 1.5rem;

    @include sm {
      font-size: 1.2rem;
    }
  }
}

.gif-box {
  .landing-search-gif {
    width: 30rem;

    @include sm {
      width: 40vw;
    }
  }
}

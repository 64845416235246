@import "mixins.scss";
@import "instantSearch.scss";
@import "app.scss";
@import "typography.scss";
@import "loginPage.scss";
@import "dashboard.scss";
@import "payment.scss";
@import "landing/landingPage.scss";

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    text-align: center;
    margin: 1rem 0.5rem;
    margin-top: -3rem;
    font-size: 5rem;
    color: #1e212d;
    letter-spacing: 2px;

    @include sm {
      font-size: 3.5rem;
      margin-top: 0rem;
    }
  }

  .subtitle {
    text-align: center;
    color: #1e212d;
    letter-spacing: 3px;
    padding: 0 1rem 2rem 1rem;
  }

  .dns-video {
    text-align: center;

    .dns-title {
      text-align: center;
    }
  }

  .results {
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-width: 1500px;

    .division {
      width: 49%;
      margin: 0.5rem;

      .card-result {
        background: #fefaee;
        border: 1px solid #fef7dc;
        box-sizing: border-box;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        padding: 1rem;
        margin: 1rem 0.5rem;
      }
    }
  }

  .types {
    display: flex;
    background-color: #fefaee;
    color: #1e212d;
    border: 3px solid #fefaee;
    border-radius: 4px;
    margin: 0.7rem 0.2rem;

    .type {
      flex-grow: 1;
      text-align: center;
      padding: 0.3rem 0.5rem;
      border-radius: 4px;
      cursor: pointer;
    }

    .current {
      background-color: #ffebc9;
      color: #1e212d;
      font-weight: bolder;
      border-radius: 0;
    }
  }

  .sub-types {
    display: flex;
    justify-content: flex-start;
    margin-top: 1.8rem;

    .type {
      background-color: #fefaee;
      color: #1e212d;
      border-radius: 1rem;
      padding: 0.5rem 0.8rem;
      font-size: 1rem;
      letter-spacing: 1px;
      margin: 0 0.6rem;
      transition: all 0.2s;

      &:hover {
        transform: scale(105%);
        box-shadow: 0 0px 8px rgba(119, 119, 119, 0.3);
      }

      &:active {
        transform: scale(100%);
        box-shadow: none;
      }
    }

    .current {
      background-color: #ffebc9;
      font-weight: bolder;
    }
  }

  .article-detail {
    img {
      width: 100%;
    }
  }

  .dns-video {
    margin: 0.5rem;
    max-width: 1000px;

    .dns-video-container {
      position: relative;
      padding: 0.5rem 0 56.25% 0;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .options {
    margin: 0.5rem 0;

    div {
      margin: 0.5rem 0;
    }
  }

  .solution {
    padding: 0.5rem;
    margin-top: 0.25rem;
    background-color: #faf3e0;
    border: 1px solid rgb(209, 213, 219);
    border-radius: 4px;

    .show {
      display: inline-block;
      width: 100%;
      cursor: pointer;
    }

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

.card-solved {
  opacity: 0.4;
}

.card-revise {
  opacity: 0.6;
}

.pyqs-solved-toggle {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  font-size: 1rem;
}

.pyqs-revised-div {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  margin-top: 1rem;

  .pyqs-revised-text {
    color: red;
  }
}

.secure-links-div,
.wfv-links-div {
  display: flex;
  justify-content: space-evenly;
}

.secure-links,
.wfv-links {
  text-decoration: none;
  background-color: #ffebc9;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}

.wfv-links-div {
  margin-top: 1.5rem;
  justify-content: flex-start;
}

.vision-links {
  text-decoration: none;
}

.back-to-top {
  position: fixed;
  bottom: 1rem;
  right: 1.5rem;
  font-size: 2rem;
  color: #1e212d;
  transition: all 0.2s;

  &:hover {
    color: blue;
    transform: scale(110%);
  }
}

.premium-alert {
  width: 97%;
  text-align: center;
  font-weight: 600;
  font-size: 1.3rem;
  background-color: rgba(#fff, 0.4);
  color: #eb5353;
  padding: 0.4rem 0.6rem;
  border-radius: 10px;
}

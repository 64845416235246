.header-card-div {
  width: 100vw;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-card-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 5rem;
}

.header-card {
  width: 35vw;
  background-color: #fff;
  padding: 2rem 2.5rem;
  margin: 0.5rem;
  border-radius: 10px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.03);

  @include sm {
    width: 90vw;
  }

  &-text {
    text-align: justify;
    font-size: 1.2rem;
  }

  &-info {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }

  &-author {
    font-weight: bolder;
    color: #333;
  }

  &-misc {
    color: #333;
  }
}

.slider {
  display: flex;
  align-items: center;
}

.header-card-youtube {
  margin: 5rem 0;
  border-radius: 5px;

  @include sm {
    width: 80vw;
    height: auto;
    margin: 2rem 0;
  }
}
